import {useQuery} from "@tanstack/react-query";

const getBlogPosts = async () => {
  const response = await fetch(
    "https://blog.lendsqr.com/wp-json/wp/v2/posts?per_page=3&page=1"
  );

  const data = await response.json();

  const thumbnail_url1 = await getMediaID(data[0].featured_media);
  const thumbnail_url2 = await getMediaID(data[1].featured_media);
  const thumbnail_url3 = await getMediaID(data[2].featured_media);

  data[0].thumbnail_url = thumbnail_url1;
  data[1].thumbnail_url = thumbnail_url2;
  data[2].thumbnail_url = thumbnail_url3;

  return data;
};

export const getMediaID = async (id: number) => {
  const response = await fetch(
    `https://blog.lendsqr.com/wp-json/wp/v2/media/${id}`
  );

  const data = await response.json();

  return data.guid.rendered;
};

const useBlogPosts = () => {
  return useQuery(["getBlogPosts"], getBlogPosts, {
    retry: 2,
  });
};

export {useBlogPosts};
